<nz-header>
  <div class="app-header">
    <div nz-row>
      <div nz-col nzSpan="13">
        <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          (click)="onChangeCollapse()"></i>
      </div>
      <div nz-col nzSpan="8" >
      </div>
      <div nz-col nzSpan="3" class="rightPart">
        <!-- <div>
          <button nz-button nzType="link" (click)="openNotificationComponent()">
            <i nz-icon nzType="bell" nzTheme="outline" class="bell-icon"></i>
          </button>
          <ng-template #Content> This is content</ng-template>
        </div> -->
        <div>
          <nz-avatar nzIcon="user" class="avatar" nzSrc="" nz-button nz-dropdown [nzDropdownMenu]="menu"
            [nzPlacement]="'bottomRight'"></nz-avatar>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item> <a [routerLink]="['/main/profile']"><i nz-icon nzType="user" nzTheme="outline"></i>My
                  Profile</a></li>
              <li nz-menu-item> <a [routerLink]="['main/settings/']"><i nz-icon nzType="setting"
                    nzTheme="outline"></i>Settings</a>
              </li>
              <li nz-menu-item (click)="logout()"><a><i nz-icon nzType="logout" nzTheme="outline"></i>Logout</a></li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </div>
  </div>
</nz-header>
