import { Component, OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ConfigurationService } from 'src/app/core/services';
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
@Component({
  selector: 'app-upload-image-for-blog',
  templateUrl: './upload-image-for-blog.component.html',
  styleUrls: ['./upload-image-for-blog.component.scss']
})
export class UploadImageForBlogComponent implements OnInit {
  mediaUploadUrl: string;
  horizontalfileList: NzUploadFile[] = [];
  previewImage = '';
  previewVisible = false;
  constructor(
    private configurationService: ConfigurationService
  ) {

    this.mediaUploadUrl = configurationService.apiUrl + '/blog/image';
   }

  ngOnInit(): void {

  }

  /** Media */
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  }

}
