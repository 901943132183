import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AuthService,
  HttpRequestService,
  LocalStorageService,
} from 'src/app/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import { CommunicationService } from 'src/app/core/services/communication.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  notificationSelectedClass = false;
  profileSelectedClass = false;
  allCommunity: any[] = [];
  selectedCommunity = '';
  showCommunity = false;
  user: any = {};
  // communityId: string;
  userInfo = [
    {
      title: 'My profile',
    },
    {
      title: 'Settings',
    },
    {
      title: 'Logout',
    },
  ];
  @Output() collapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private communicationService: CommunicationService,
    private localStorageService: LocalStorageService,
    private drawerService: NzDrawerService
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('community-management/manage')) {
          this.showCommunity = true;
        } else {
          this.showCommunity = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.localStorageService.getItem('user');
  }
  onChangeCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  logout(): void {
    const params: any = {};
    params.admin_uid = this.user.admin_uid;
    this.httpRequestService
      .request('get', 'admin/logout')
      .subscribe((success) => {
        this.notificationService.success(
          'Success',
          'You Successfully Logged out'
        );
      });
    this.authService.logout();
  }


}
