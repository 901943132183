<div style="text-align: center;">

  <nz-upload
  class="avatar-uploader"
  nzName="avatar"
  [nzAction]="mediaUploadUrl"
  [nzHeaders]="customRequestHeaders"
  [nzName]="'blog'"
  [(nzFileList)]="horizontalfileList"
  [nzPreview]="handlePreview"
  >
  <button nz-button>
    <i nz-icon nzType="upload"></i>
    Click to Upload
  </button>
  </nz-upload>
</div>
<nz-card *ngIf="horizontalfileList && horizontalfileList.length" style="margin-top: 5px;">
  <p style="text-align: center;">Image URL</p>
  <p nz-typography nzCopyable nzCopyText="{{horizontalfileList[0]?.response?.response}}" >{{horizontalfileList[0]?.response?.response}}</p>
</nz-card>
