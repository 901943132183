import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor() { }
  get apiUrl(): string {
    return environment.production ? 'https://apiv2.zyephr.com' : ' https://apiv2.zyephr.com' ;
    // return  'https://api-stage.zyephr.com';
    // return  'https://apiv2dev.zyephr.com';
    // return 'http://127.0.0.1:5001'
  }

  get mediaBaseUrl(): string {
    return environment.production ? 'https://zyephr-prod-storage.s3.ap-south-1.amazonaws.com/' : 'https://zyephr-prod-storage.s3.ap-south-1.amazonaws.com/';
    // return environment.production ? 'https://zyephr-dev-storage.s3.ap-south-1.amazonaws.com/' : 'https://zyephr-dev-storage.s3.ap-south-1.amazonaws.com/';
  }
}
